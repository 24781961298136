<template>
  <!-- Main Wrapper -->
  <div class="main-wrapper">
    <layouts></layouts>
    <sidebar></sidebar>
    <div class="page-wrapper">
      <div class="content">
        <pageheader :title="title" :title1="title1" />
        <div class="card" v-if="orderFetched">
          <div class="card-body">
            <div class="card-sales-split">
              <div>
                <span class="d-block">
                  <b class="fw-bold">Order Reference : </b>{{ order.reference }}
                </span>
                <span class="d-block" v-if="order.requested_by">
                  <b class="fw-bold">Requested By : </b>{{ order.requested_by.name }}
                </span>
              </div>
              <ul style="align-items: center;">
                <li>
                  <a href="javascript:void(0);" @click="exportToPdf"><img src="../../../assets/img/icons/printer.svg"
                      alt="img" /></a>
                </li>
                <li>
                  <a href="javascript:void(0);" @click="exportToPosPdf">
                    <img style="width: 30px" src="../../../assets/img/icons/pos.svg" alt="img" /></a>
                </li>
              </ul>
            </div>
            <div id="order-detail">
              <div class="invoice-box table-height" style="
                max-width: 1600px;
                width: 100%;
                overflow: auto;
                margin: 15px auto;
                padding: 0;
                font-size: 14px;
                line-height: 24px;
                color: #555;
              ">
                <table cellpadding="0" cellspacing="0" style="width: 100%; line-height: inherit; text-align: left">
                  <tbody>
                    <tr class="top">
                      <td colspan="6" style="padding: 5px; vertical-align: top">
                        <table style="
                          width: 100%;
                          line-height: inherit;
                          text-align: left;
                        ">
                          <tbody>
                            <tr>
                              <td style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              ">
                                <font style="
                                  vertical-align: inherit;
                                  margin-bottom: 25px;
                                ">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  ">Customer Info</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    {{ order.user.name }}</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    {{ order.user.email }}</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    {{ order.user.mobile_number }}</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">{{ order.user.address }}</font>
                                </font><br />
                              </td>
                              <td style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: left;
                                padding-bottom: 20px;
                              ">
                                <font style="
                                  vertical-align: inherit;
                                  margin-bottom: 25px;
                                ">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  ">Invoice Info</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    Reference
                                  </font>
                                </font><br />
                                <font style="vertical-align: inherit" v-if="order.payment_date">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    Payment Date
                                </font><br />
                                  </font>
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    Payment Status</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">
                                    Status</font>
                                </font><br />
                              </td>
                              <td style="
                                padding: 5px;
                                vertical-align: top;
                                text-align: right;
                                padding-bottom: 20px;
                              ">
                                <font style="
                                  vertical-align: inherit;
                                  margin-bottom: 25px;
                                ">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #7367f0;
                                    font-weight: 600;
                                    line-height: 35px;
                                  ">&nbsp;</font>
                                </font><br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">{{ order.reference }}
                                  </font>
                                </font><br />
                                <font style="vertical-align: inherit" v-if="order.payment_date">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #000;
                                    font-weight: 400;
                                  ">{{ order.payment_date ? formatTimeStamp(order.payment_date, false) : '---' }}
                                  </font><br />
                                </font>
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  ">
                                    {{ order.payment_status }}
                                    <span v-if="order.due > 0">
                                      ({{ this.formatAmount(order.due) }})
                                    </span>
                                  </font>
                                </font>
                                <br />
                                <font style="vertical-align: inherit">
                                  <font style="
                                    vertical-align: inherit;
                                    font-size: 14px;
                                    color: #2e7d32;
                                    font-weight: 400;
                                  ">
                                    {{ order.status }}</font>
                                </font><br />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr class="heading" style="background: #f3f2f7">
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        Product Name
                      </td>
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        QTY
                      </td>
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        Price
                      </td>
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        Discount
                      </td>
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        TAX
                      </td>
                      <td style="
                        padding: 5px;
                        vertical-align: middle;
                        font-weight: 600;
                        color: #5e5873;
                        font-size: 14px;
                        padding: 10px;
                      ">
                        Subtotal
                      </td>
                    </tr>

                    <tr v-for="product in order.products" :key="product.id" class="details"
                      style="border-bottom: 1px solid #e9ecef">
                      <td style="
                        padding: 10px;
                        vertical-align: top;
                        display: flex;
                        align-items: center;
                      ">
                        <img :src="product.image" alt="img" class="me-2" style="width: 40px; height: 40px" />
                        {{ product.name }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">
                        {{ product.qty }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">
                        {{ formatAmount(product.price) }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">
                        {{ formatAmount(product.discount) }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">
                        {{ formatAmount(product.tax) }}
                      </td>
                      <td style="padding: 10px; vertical-align: top">
                        {{ formatAmount(product.sub_total) }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row">
                <div class="row">
                  <div class="col-lg-6">
                    <div class="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>Order Tax</h4>
                          <h5>₦ {{ order.tax }}</h5>
                        </li>
                        <li>
                          <h4>Discount</h4>
                          <h5>₦ {{ order.discount }}</h5>
                        </li>
                        <li>
                          <h4>Promo Amount</h4>
                          <h5>₦ {{ 0 }}</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="total-order w-100 max-widthauto m-auto mb-4">
                      <ul>
                        <li>
                          <h4>Shipping</h4>
                          <h5>₦ {{ order.shipping }}</h5>
                        </li>
                        <li>
                          <h4>Amount Paid</h4>
                          <h5>₦ {{ order.paid }}</h5>
                        </li>
                        <li class="total">
                          <h4>Grand Total</h4>
                          <h5>₦ {{ order.total }}</h5>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <!------ ORDER PAYMENT START---------->
        <f-table v-if="order.payments" :filters="['amount', 'reference']" :columns="paymentColumns"
          :data="orderPayments">
          <template #top>
            <div class="card-sales-split">
              <h2>Order Payments</h2>
            </div>
          </template>
          <template #bodyCell="{ column, record }">
            <template v-if="column.key == 'paid_by'">
              <router-link v-if="record.paid_by.id" title="Click to View Admin" :to="'admin/' + record.paid_by.id">{{
                record.paid_by.name }}</router-link>
              <a href="javaScript:void(0)" v-else>
                {{ record.paid_by.name }}
              </a>
            </template>
          </template>
        </f-table>
        <!------ ORDER PAYMENT END---------->
        <!------ PAYMENT RECEIPTS START---------->
        <div class="card" v-if="order.receipts">
          <div class="card-body">
            <div class="card-sales-split">
              <h2>Payment Receipts</h2>
            </div>

            <div class="d-flex">
              <a v-for="(image, index) in order.receipts" :key="index" title="Click to View Image" target="_blank"
                :href="image.url" class="receipt">
                <img :src="image.url" />
                <caption class="caption">
                  Date:
                  {{
                    image.date
                  }}
                </caption>
              </a>
            </div>
          </div>
        </div>
        <!------ PAYMENT RECEIPTS END---------->
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { _sale } from "@/store/models";
import html2pdf from "html2pdf.js";
import store from "@/store";
import { formatTimeStamp } from '../../../helpers';
// import {formatTimeStamp} from "@/helpers"

const paymentColumns = [
  {
    title: "Amount",
    dataIndex: "amount",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
  {
    title: "Reference",
    dataIndex: "reference",
    sorter: (a, b) => a.reference.localeCompare(b.reference),
  },
  {
    title: "Payment Method",
    dataIndex: "method",
    sorter: (a, b) => a.method.localeCompare(b.method),
  },
  {
    title: "Paid By",
    key: "paid_by",
  },
  {
    title: "Date",
    dataIndex: "date",
    sorter: (a, b) => a.date.localeCompare(b.date),
  },
];
export default {
  data() {
    return {
      formatTimeStamp,
      title: "Order Detail",
      title1: "View Order details",
      order: _sale,
      paymentColumns,
      orderFetched: false,
      Status: ["Choose Status", "Completed", "Inprogress"],
      orderPayments: [],
    };
  },
  computed: {
    ...mapGetters(["formatAmount"]),
  },
  methods: {
    ...mapActions({
      _getOrderDetail: "orders/show",
    }),
    exportToPdf() {
      html2pdf(document.getElementById("order-detail"), {
        margin: 5,
        filename: this.order.reference + ".pdf",
        enableLinks: true,

        image: {
          type: "png",
          quality: 50.98,
        },
      });
    },
    exportToPosPdf() {
      store.dispatch("print/printOrderReceipt", this.order.id);
    },

    getOrderDetail() {
      let id = this.$route.params.id;
      this._getOrderDetail({ order_id: id }).then((response) => {
        this.order = response.data.data;
        if (this.order.payments) {
          this.orderPayments = this.order.payments.map((payment) => {
            payment.amount = `₦ ${payment.amount}`; // Add Naira Sign
            return payment;
          });
        }
        this.orderFetched = true;
      });
    },
  },
  mounted() {
    this.getOrderDetail();
  },
  name: "order-details",
};
</script>
